import { combineReducers } from "redux";
import auth from "./auth";
import message from "./message";
import hasPayed from "./hasPayed";

export default combineReducers({
  auth,
  message,
  hasPayed,
});
