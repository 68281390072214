import axios from "axios";

class AuthService {
    login(email, password) {
        return axios
            .post(process.env.REACT_APP_API_BASE_URL + "/api/user/login", {
                "email": email,
                "password": password
            })
            .then((response) => {
                if (response.status === 200) {
                    if (response.data.data.token) {

                        axios.get(process.env.REACT_APP_API_BASE_URL + "/api/user/user_data", {
                            headers: {
                                Authorization: "Bearer " + response.data.data.token
                            }
                        }).then(r => {
                            localStorage.setItem("profile", JSON.stringify(r.data))
                            localStorage.setItem("user", JSON.stringify(response.data.data));
                            localStorage.setItem("error", "");
                        })
                    } else {
                        localStorage.setItem("error", "من فضلك تأكد من البريد الألكتروني او كلمة المرور");
                    }
                    return response.data;
                } else {
                    localStorage.setItem("error", "من فضلك تأكد من البريد الألكتروني او كلمة المرور");
                }

            }).catch((error) => {
                localStorage.setItem("error", "من فضلك قم بتاكيد البريد الألكتروني ");
            })
    }

    logout() {
        localStorage.removeItem("user");
        localStorage.removeItem("profile");
        localStorage.removeItem("parent");
        localStorage.clear()
    }

    register(firstName, secondName, phone, email, city, district, password, confirmPassword, vendorOption, vendor) {
        return axios.post(process.env.REACT_APP_API_BASE_URL + "/api/users/auth/users/", {
            "first_name": firstName,
            "last_name": secondName,
            "phone_number": phone,
            "email": email,
            "city": city,
            "district": district,
            "agent_name": vendorOption,
            "password": password,
            "re_password": confirmPassword,
            "reg_as_vendor": vendor
        });
    }
}

export default new AuthService();
