import { PAYED } from "../actions/types";

const initialState = localStorage.getItem("payed")
  ? (() => {
      try {
        let val = localStorage.getItem("payed");
        if (val) {
          return JSON.parse(val);
        } else return false;
      } catch (e) {
        console.log("Error parsing JSON from localStorage", e);
        return false;
      }
    })()
  : false;

export default function hasPayedReducer(state = initialState, action) {
  switch (action.type) {
    case PAYED:
      // Save to localStorage when user has paid
      localStorage.setItem("payed", JSON.stringify(action.payload));
      return action.payload;

    default:
      return state;
  }
}
