import "./App.css";
import NavBar from "./Component/Navbar";
import pMinDelay from "p-min-delay";
import { history } from "./helpers/history";
import React from "react";
import { connect } from "react-redux";
import loadable from "./Component/loader/loadable";
import Loading from "./Component/loader";
import Lessons from "./Component/Lessons";
import { BrowserRouter, Route, Router, Switch } from "react-router-dom";
import Footer from "./Component/Footer";
import { clearMessage } from "./actions/message";
import "react-toastify/dist/ReactToastify.css";
import EventBus from "./common/EventBus";
import axios from "axios";
import { logout } from "./actions/auth";
import { PrivateRoute } from "./_PrivateRoutes";
import { PrivateRouteParent } from "./_PrivateRoutesParent";
import { PrivateRouteTeacher } from "./_PrivateRoutesTeacher";
import { ToastContainer } from "react-toastify";

const Home = loadable(() => pMinDelay(import("./Pages/HomePage"), 250), {
  fallback: <Loading />,
});
const LessonsCom = loadable(
  () => pMinDelay(import("./Pages/AllLessons"), 250),
  { fallback: <Loading /> }
);
const AnnualCourses = loadable(
  () => pMinDelay(import("./Pages/AnnualCoursesPage"), 250),
  {
    fallback: <Loading />,
  }
);
const LessonsAcademicCom = loadable(
  () => pMinDelay(import("./Pages/AllLessonsAcademic"), 250),
  { fallback: <Loading /> }
);
const Trainings = loadable(
  () => pMinDelay(import("./Pages/AllTrainings"), 250),
  { fallback: <Loading /> }
);
const Skip = loadable(() => pMinDelay(import("./Pages/Skip"), 250), {
  fallback: <Loading />,
});
const SignIn = loadable(() => pMinDelay(import("./Pages/SignIn"), 250), {
  fallback: <Loading />,
});
const CreateZoom = loadable(
  () => pMinDelay(import("./Pages/CreateZoom"), 250),
  { fallback: <Loading /> }
);
const SignUp = loadable(() => pMinDelay(import("./Pages/SignUp"), 250), {
  fallback: <Loading />,
});
const SignUpTeacher = loadable(
  () => pMinDelay(import("./Pages/SignUpTeacher"), 250),
  { fallback: <Loading /> }
);
const Welcome = loadable(() => pMinDelay(import("./Pages/Welcome"), 250), {
  fallback: <Loading />,
});
const SingleCourse = loadable(
  () => pMinDelay(import("./Pages/SingleCourse"), 250),
  { fallback: <Loading /> }
);
const Promotions = loadable(
  () => pMinDelay(import("./Pages/Promotions"), 250),
  { fallback: <Loading /> }
);
const Eshtrak = loadable(() => pMinDelay(import("./Pages/EshtrakPage"), 250), {
  fallback: <Loading />,
});
const Quizzes = loadable(() => pMinDelay(import("./Pages/Quizzes"), 250), {
  fallback: <Loading />,
});
const QuizzesStudent = loadable(
  () => pMinDelay(import("./Pages/QuizzesStudentPage"), 250),
  { fallback: <Loading /> }
);
const MoreInfoCourse = loadable(
  () => pMinDelay(import("./Pages/MoreInfoCourse"), 250),
  { fallback: <Loading /> }
);
const MoreVideos = loadable(
  () => pMinDelay(import("./Pages/MoreVideos"), 250),
  { fallback: <Loading /> }
);
const ForgetPassword = loadable(
  () => pMinDelay(import("./Pages/ForgetPassword"), 250),
  { fallback: <Loading /> }
);
const ChangePassword = loadable(
  () => pMinDelay(import("./Pages/ChangePassword"), 250),
  { fallback: <Loading /> }
);
const ProfileSettings = loadable(
  () => pMinDelay(import("./Pages/ProfileSettings"), 250),
  { fallback: <Loading /> }
);
const EditMoreCourse = loadable(
  () => pMinDelay(import("./Pages/EditMoreCourse"), 250),
  { fallback: <Loading /> }
);
const CourseInfo = loadable(
  () => pMinDelay(import("./Pages/CourseInfo"), 250),
  { fallback: <Loading /> }
);
const ScrollToTop = loadable(
  () => pMinDelay(import("./Component/ScrollToTop"), 250),
  { fallback: <Loading /> }
);
const Teachers = loadable(() => pMinDelay(import("./Pages/Teachers"), 250), {
  fallback: <Loading />,
});
const AddBookPrice = loadable(
  () => pMinDelay(import("./Pages/AddBookPrice"), 250),
  { fallback: <Loading /> }
);
const SingleSubject = loadable(
  () => pMinDelay(import("./Pages/SingleSubject"), 250),
  { fallback: <Loading /> }
);
const SubscriptionTypes = loadable(
  () => pMinDelay(import("./Pages/SubscriptionTypes"), 250),
  { fallback: <Loading /> }
);
const ContentTypes = loadable(
  () => pMinDelay(import("./Pages/ContentTypes"), 250),
  { fallback: <Loading /> }
);
const AllQuizzes = loadable(
  () => pMinDelay(import("./Pages/AllQuizzes"), 250),
  { fallback: <Loading /> }
);
const SingleTeacher = loadable(
  () => pMinDelay(import("./Pages/SingleTeacher"), 250),
  { fallback: <Loading /> }
);
const Activities = loadable(() => pMinDelay(import("./Pages/Activity"), 250), {
  fallback: <Loading />,
});
const Students = loadable(() => pMinDelay(import("./Pages/StudentPage"), 250), {
  fallback: <Loading />,
});
const singleStudent = loadable(
  () => pMinDelay(import("./Pages/SingleStudentPage"), 250),
  { fallback: <Loading /> }
);
const Reels = loadable(() => pMinDelay(import("./Pages/ReelsPage"), 250), {
  fallback: <Loading />,
});
const TeacherProfile = loadable(
  () => pMinDelay(import("./Pages/TeacherProfilePage"), 250),
  { fallback: <Loading /> }
);
const Saved = loadable(() => pMinDelay(import("./Pages/SavedPage"), 250), {
  fallback: <Loading />,
});
const SignInParent = loadable(
  () => pMinDelay(import("./Pages/SignInParentPage"), 250),
  { fallback: <Loading /> }
);
const ParentHomePage = loadable(
  () => pMinDelay(import("./Pages/ParentHomePage"), 250),
  { fallback: <Loading /> }
);
const NewPaymentPage = loadable(
  () => pMinDelay(import("./Pages/EshtrakPagePaymentNew"), 250),
  { fallback: <Loading /> }
);
const Terms = loadable(() => pMinDelay(import("./Pages/TermsPage"), 250), {
  fallback: <Loading />,
});
const GeneralVideos = loadable(
  () => pMinDelay(import("./Pages/AllVideosPage"), 250),
  { fallback: <Loading /> }
);
const Success = loadable(
  () => pMinDelay(import("./Pages/SuccessPayment"), 250),
  { fallback: <Loading /> }
);
const Failure = loadable(
  () => pMinDelay(import("./Pages/FailurePayment"), 250),
  { fallback: <Loading /> }
);

class App extends React.Component {
  constructor(props) {
    super(props);
    this.logOut = this.logOut.bind(this);

    this.state = {
      showModeratorBoard: false,
      showAdminBoard: false,
      currentUser: undefined,
      shouldLogin: false,
      DataLinks: {},
      FooterComp: [],
    };

    history.listen((location) => {
      props.dispatch(clearMessage()); // clear message when changing location
    });
  }

  componentDidMount() {
    const user = this.props.user;
    if (this.props.isLoggedIn) {
      axios
        .get(process.env.REACT_APP_API_BASE_URL + "/api/user/user_data", {
          headers: {
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("user")).token,
          },
        })
        .then((r) => {
          localStorage.setItem("profile", JSON.stringify(r.data));
          localStorage.setItem("error", "");
        })
        .catch((error) => {
          this.setState({ shouldLogin: true });
          localStorage.clear();
          this.logOut();
        });
    } else {
      localStorage.setItem("error", "");
    }

    if (user) {
      this.setState({
        currentUser: user,
      });
    }

    EventBus.on("logout", () => {
      this.logOut();
    });

    axios
      .get(process.env.REACT_APP_API_BASE_URL + "/api/user/setting")
      .then((r) => {
        let DataLinks = r.data.data;
        this.setState({ DataLinks }, () => {
          this.setState({
            FooterComp: <Footer DataLinks={this.state.DataLinks} />,
          });
        });
      });
  }

  componentWillUnmount() {
    EventBus.remove("logout");
  }

  logOut() {
    this.props.dispatch(logout());
    this.setState({
      showModeratorBoard: false,
      showAdminBoard: false,
      currentUser: undefined,
    });
  }

  render() {
    return (
      <>
        <BrowserRouter>
          <Router history={history}>
            <ScrollToTop />
            <ToastContainer />
            <NavBar />
            <Switch>
              <Route path="/" exact component={Home} />
              <Route path="/signIn" component={SignIn} />
              <Route path="/lessons" component={LessonsCom} />
              <Route path="/ig" component={LessonsAcademicCom} />
              <Route path="/american" component={LessonsAcademicCom} />
              <Route path="/stem" component={LessonsAcademicCom} />
              <Route path="/language" component={LessonsAcademicCom} />
              <Route path="/general" component={LessonsAcademicCom} />
              <Route path="/trainings" component={Trainings} />
              <Route path="/AnnualCoursesPage" component={AnnualCourses} />

              <Route path="/signUp" component={SignUp} />
              {/* <Route path='/signUpTeacher' component={SignUpTeacher}/> */}
              <Route path="/createZoom" component={CreateZoom} />
              {/*<Route path='/welcome' component={Welcome}/>*/}
              <PrivateRouteTeacher path="/promotions" component={Promotions} />
              {/*<PrivateRoute path='/eshtrakPayment' component={Eshtrak}/>*/}
              <PrivateRouteTeacher
                path="/addQuizQuestions/:id"
                component={Quizzes}
              />
              <PrivateRoute
                path="/QuizStudent/:id"
                component={QuizzesStudent}
              />
              <Route path="/forgetPassword" component={ForgetPassword} />
              <PrivateRoute path="/suggestCourses" component={AddBookPrice} />
              <PrivateRoute path="/changePassword" component={ChangePassword} />
              <PrivateRoute
                path="/profileSettings"
                component={ProfileSettings}
              />
              <PrivateRoute path="/skip" component={Skip} />
              <Route path="/singleCourse/:id" component={SingleCourse} />
              <Route
                path="/moreInfoCourse/:id/:teacher"
                component={MoreInfoCourse}
              />
              <Route
                path="/teacherProfileVideos/:id/:teacher"
                component={MoreVideos}
              />
              <PrivateRouteTeacher
                path="/addVideo"
                component={EditMoreCourse}
              />
              <Route path="/CourseInfo/:id" component={CourseInfo} />
              <Route path="/Teachers/:id/:name" component={Teachers} />
              <Route
                path="/SingleSubject/:id/:name/:teacher"
                component={SingleSubject}
              />
              <Route path="/SubscriptionTypes" component={SubscriptionTypes} />
              <Route
                path="/ContentTypes/:courseID/:videoID"
                component={ContentTypes}
              />
              <PrivateRoute
                path="/allQuizzes/:id/:teacher"
                component={AllQuizzes}
              />
              <Route path="/singleTeacher" component={SingleTeacher} />
              <PrivateRoute path="/activities" component={Activities} />
              <PrivateRoute path="/students" component={Students} />
              <PrivateRoute path="/singleStudent" component={singleStudent} />
              <PrivateRoute path="/reels" component={Reels} />
              <PrivateRouteTeacher
                path="/teacherProfile"
                component={TeacherProfile}
              />
              <PrivateRoute path="/saved" component={Saved} />
              <Route path="/parentLogin" component={SignInParent} />
              <Route path="/terms&conditions" component={Terms} />
              <Route path="/SubscribePayment" component={NewPaymentPage} />
              <PrivateRouteParent
                path="/parentHomePage"
                component={ParentHomePage}
              />
              <PrivateRoute path="/viewVideos" component={GeneralVideos} />
              <PrivateRoute path="/payment/success" component={Success} />
              <PrivateRoute path="/payment/fail" component={Failure} />
            </Switch>
            <Footer />
            {/* {this.state.FooterComp} */}
          </Router>
        </BrowserRouter>
      </>
    );
  }
}

function mapStateToProps(state) {
  const { user, profile, isLoggedIn } = state.auth;
  return {
    user,
    profile,
    isLoggedIn,
  };
}

export default connect(mapStateToProps)(App);
