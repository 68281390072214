import React from 'react'
import {Spin} from "antd";

const Loader = () => {
    return (
        <>
            <div className="loader_wrapper">
                <Spin/>
            </div>
        </>
    )
}

export default Loader