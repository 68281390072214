import {
  LOGIN_FAIL,
  LOGIN_SUCCESS,
  LOGOUT,
  REGISTER_FAIL,
  REGISTER_SUCCESS,
  SET_MESSAGE,
} from "./types";

import AuthService from "../services/auth.service";
import axios from "axios";

export const register =
  (
    firstName,
    secondName,
    phone,
    email,
    city,
    district,
    password,
    confirmPassword,
    vendorOption,
    vendor
  ) =>
  (dispatch) => {
    return AuthService.register(
      firstName,
      secondName,
      phone,
      email,
      city,
      district,
      password,
      confirmPassword,
      vendorOption,
      vendor
    ).then(
      (response) => {
        dispatch({
          type: REGISTER_SUCCESS,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: response.data.message,
        });

        return Promise.resolve();
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.response.data.email
            ? "هذا البريد الألكتروني موجود بالفعل برجاء التسجيل ببريد ألكتروني اخر"
            : error.response.data.username
            ? error.response.data.username[0]
            : error.response.data.phone_number
            ? "هذا الرقم موجود بالفعل برجاء التسجيل برقم اخر"
            : error.response.data.password
            ? error.response.data.password[0]
            : "كلمة المرور وتاكيد كلمة المرور غير متطابقتين" ||
              error.toString();

        dispatch({
          type: REGISTER_FAIL,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });

        return Promise.reject();
      }
    );
  };

export const login = (email, password) => (dispatch) => {
  return AuthService.login(email, password)
    .then(
      (data) => {
        axios
          .get(process.env.REACT_APP_API_BASE_URL + "/api/user/user_data", {
            headers: {
              Authorization: "Bearer " + data.data.token,
            },
          })
          .then((r) => {
            localStorage.setItem("user", JSON.stringify(data.data));
            localStorage.setItem("profile", JSON.stringify(r.data));
            dispatch({
              type: LOGIN_SUCCESS,
              payload: { user: data.data, profile: r.data },
            });
          })
          .catch((r) => {
            dispatch({
              type: LOGIN_FAIL,
            });
          });
        return data;
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.response.data.detail ||
          error.toString();

        dispatch({
          type: LOGIN_FAIL,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });

        return Promise.reject();
      }
    )
    .catch((error) => {
      return 403;
    });
};

export const logout = () => (dispatch) => {
  AuthService.logout();

  dispatch({
    type: LOGOUT,
  });
};

export const pay = () => (dispatch) => {
  dispatch({
    type: "PAY",
  });
};
