import React from "react";
import "./style.css";
import "./omara.css";
import Logo from "../images/logo.svg";
import Facebook from "../images/facebook.svg";
import Instagram from "../images/instagram.svg";
import Tiktok from "../images/tiktok.svg";
import Wave from "../images/wave.svg";
import { Link, withRouter } from "react-router-dom";
import axios from "axios";

class Footer extends React.Component {
  state = {
    DataLinks: {},
    message: "",
  };

  sendForm = () => {
    const RegisterData = new FormData();

    RegisterData.append("message", this.state.message);
    const user = JSON.parse(localStorage.getItem("user"));
    if (user) {
      RegisterData.append("email", "mo.mostafa@nu.edu.eg");
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "/api/user/contact_us_create",
          RegisterData
        )
        .then((res) => {
          window.alert("message sent!");
          this.setState({ message: "" });
        })
        .catch((error) => {
          window.alert("error");
          console.error("Error sending the form:", error);
        });
    } else {
      window.location.href = "/signin";
    }
  };
  componentDidMount() {
    axios
      .get(process.env.REACT_APP_API_BASE_URL + "/api/user/setting")
      .then((r) => {
        let DataLinks = r.data.data;
        this.setState({ DataLinks });
      });
  }

  // Define a function to handle changes in the input value
  handleInputChange = (event) => {
    this.setState({ message: event.target.value });
  };

  render() {
    return (
      <>
        <div className="">
          <div className="main-footer ">
            <div className="container">
              <div className="row gap-4 row-mob justify-content-around ">
                <div className="col-lg-3 col-md-4 col-sm-12 justify-content-center">
                  <img src={Logo} className="footer-logo mob-none" />
                  <h5 className="mob-none">عن الديوان</h5>
                  <p className="mob-none">
                    {" "}
                    منصه بتقدم كورسات فى البيزنس فقط مع نخبه من اساتذه الجامعه
                  </p>
                  <div className="social-media-account">
                    <a
                      href={this.state.DataLinks?.instagram_url}
                      target="_blank"
                    >
                      <div className="sub-social">
                        <img src={Instagram} />
                      </div>
                    </a>
                    <a href={this.state.DataLinks?.tiktok_url} target="_blank">
                      <div className="sub-social">
                        <img src={Tiktok} />
                      </div>
                    </a>
                    <a
                      href={this.state.DataLinks?.facebook_url}
                      target="_blank"
                    >
                      <div className="sub-social">
                        <img src={Facebook} />
                      </div>
                    </a>
                  </div>
                </div>
                <div className="col-5 d-none d-lg-flex row half-footer pt-5 justify-content-between">
                  <div className="col-lg-6 col-md-6 col-sm-12 footer-col-1 d-flex  flex-column justify-content-center">
                    <h6 className="mr-4">أكتشف المزيد</h6>
                    <ul className="footer-list-1   text-nowrap ">
                      <li>
                        <Link to="/singleTeacher">
                          <p>&nbsp;دوراتنا&nbsp;التدريبية</p>
                        </Link>
                      </li>
                      {/* <li><Link to="/lessons"><p >&nbsp;موادنا التعليمية</p></Link></li> */}
                      <li>
                        <Link to="/singleTeacher">
                          <p>&nbsp;الدورات الأكثر شيوعا</p>
                        </Link>
                      </li>
                      {/* <li><Link to="/singleTeacher"><p >&nbsp;عن المعلم</p></Link></li> */}
                    </ul>
                  </div>
                  <div className="col-lg-6 col-md-4 col-sm-12 footer-col-1 d-flex  flex-column justify-content-center ">
                    <h6>المزيد</h6>
                    <ul className="footer-list-2   text-nowrap">
                      {/* <li>  <Link to="/singleTeacher"><p >عن الديوان</p></Link></li> */}
                      <li>
                        {" "}
                        <a
                          href={`https://wa.me/${this.state.DataLinks?.whatsapp_number}`}
                          target="_blank"
                        >
                          {" "}
                          <div>تواصل معنا</div>
                        </a>
                      </li>
                      <li>
                        {" "}
                        <Link to={"/terms&conditions"}>
                          <p>الشروط و الاحكام </p>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="col-md-3 col-sm-12  footer-col-2 justify-content-center">
                  <p className="text-center">ابق على اطلاع بأحدث الدورات</p>
                  <div className="d-flex footer-input-group">
                    <input
                      value={this.state.message}
                      onChange={this.handleInputChange}
                      className="footer-sumbit"
                    />
                    <button
                      onClick={this.sendForm}
                      className="new-btn paddingSides "
                    >
                      إرسال
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="text-center p-1 lower-footer">
            <div class="text-light">جميع الحقوق محفوظة لمنصة الديوان 2023 </div>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(Footer);
